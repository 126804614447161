import React from 'react'

import AttractionIcon from 'assets/icons/attraction-16x16.svg'
import GuideIcon from 'assets/icons/guide-16x16.svg'
import ExperienceIcon from 'assets/icons/smile-16x16.svg'
import {ENTITIES} from 'consts.json'

import * as S from './Styled'

const ICONS = {
  other: '/static/images/search-result-pin.svg',
  [ENTITIES.GUIDE]: '/static/images/blue_guides_icon.svg',
  [ENTITIES.ATTRACTION]: '/static/images/search-result-attraction.svg',
  [ENTITIES.EXPERIENCE]: '/static/images/experiences_search_icon.svg'
}

const entityTypes = [ENTITIES.GUIDE, ENTITIES.EXPERIENCE, ENTITIES.ATTRACTION]
const locationTypes = [ENTITIES.CITY, ENTITIES.REGION, ENTITIES.COUNTRY]

function ResultLocation({data}) {
  return (
    <S.ResultComponentWrapper>
      <S.Icon src={ICONS[entityTypes.includes(data.entityType) ? data.entityType : 'other']} />
      <S.ResultsWrapper>
        <S.ResultComponentText>{data.title}</S.ResultComponentText>
        {locationTypes.includes(data.entityType) ? (
          <S.EntityCountsWrapper>
            {data.attractionCount > 0 ? (
              <S.EntityCount>
                <AttractionIcon />
                <S.EntityCountText>{data.attractionCount}</S.EntityCountText>
              </S.EntityCount>
            ) : null}
            {data.experienceCount > 0 ? (
              <S.EntityCount>
                <ExperienceIcon />
                <S.EntityCountText>{data.experienceCount}</S.EntityCountText>
              </S.EntityCount>
            ) : null}
            {data.guideCount > 0 ? (
              <S.EntityCount>
                <GuideIcon />
                <S.EntityCountText>{data.guideCount}</S.EntityCountText>
              </S.EntityCount>
            ) : null}
          </S.EntityCountsWrapper>
        ) : null}
      </S.ResultsWrapper>
    </S.ResultComponentWrapper>
  )
}

export default ResultLocation
