import React from 'react'

import * as S from './Styled'

const FAQCard = ({question, answer}) => {
  return (
    <S.Wrapper>
      <S.AccordionItem>
        <S.Summary expandIcon={<S.Icon src="/static/images/plus-black.svg" />}>
          <S.QuestionWrapper>{question}</S.QuestionWrapper>
        </S.Summary>
        <S.Details>{answer}</S.Details>
      </S.AccordionItem>
    </S.Wrapper>
  )
}

export default FAQCard
