import React from 'react'

import CPAttractionLink from '../../../CPAttractionLink'
import CPCardItem from '../../../common/CPCardItem'

import * as S from './styled'

function GridItem(props) {
  return (
    <CPAttractionLink slug={props.slug}>
      <S.ItemWrapper>
        <CPCardItem className="grid-item-wrapper" {...props} />
      </S.ItemWrapper>
    </CPAttractionLink>
  )
}

export default GridItem
