import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  ${props => props.theme.mediaQuery.lg} {
    width: 60%;
  }
`
export const Icon = styled.img`
  width: 24px;
  height: 24px;
`

export const AccordionItem = styled(Accordion)`
  background: white !important;
  border-radius: 16px !important;
  border: 0 !important;
  box-shadow: none !important;
  width: 100%;

  :before {
    content: none !important;
  }

  > div {
    justify-content: space-between !important;
    align-items: flex-start !important;
  }

  .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd {
    transform: rotate(45deg) !important;
  }

  .MuiTouchRipple-root {
    display: none;
  }
`

export const Summary = styled(AccordionSummary)`
  color: #000 !important;
  font-family: Nunito Sans !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  border-radius: 16px !important;
  padding: 32px !important;

  ${props => props.theme.mediaQuery.lg} {
    font-size: 20px !important;
  }
`

export const QuestionWrapper = styled.h3`
  font-weight: normal;
  margin: 0;
  font-size: inherit;
`

export const Details = styled(AccordionDetails)`
  border-radius: 16px;
  padding: 32px !important;
  padding-top: 0 !important;
`
