import {ENTITIES} from '../../consts.json'

export const getFormattedLocation = item => {
  const newLocation = {}
  if (item.entityType === ENTITIES.COUNTRY) {
    newLocation.countryId = item.id
  } else if (item.entityType === ENTITIES.REGION) {
    newLocation.regionId = item.id
  } else if (item.entityType === ENTITIES.CITY) {
    newLocation.cityId = item.id
  }
  return newLocation
}
