import useModel from './useModel'

const EXPERIENCE_LIMIT = 6
const GUIDES_LIMIT = 8
const ATTRACTIONS_LIMIT = 8

function useLocalState(props) {
  const {model, ModelProvider} = useModel(props)

  return {model, ModelProvider, EXPERIENCE_LIMIT, GUIDES_LIMIT, ATTRACTIONS_LIMIT}
}

export default useLocalState
