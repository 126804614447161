import React from 'react'

import withTranslation from 'common/HOCs/withTranslation'
import {formatPrice} from 'common/helpers'

import * as S from './styled'

function GridItem(props) {
  const {slug, src, title, price, currency, isFree, loading, t, image} = props
  return (
    <S.GuideLink href={`/tour/${slug}`}>
      <S.Wrapper className="grid-item-wrapper">
        <S.ImageWrapper loading={loading} className="grid-image-wrapper">
          {loading ? null : <S.Image src={src ? src : image ? image.path : ''} />}
        </S.ImageWrapper>
        <S.TextWrapper>
          <S.Title loading={loading}>{title}</S.Title>
          {isFree ? (
            <S.Subtitle loading={loading}>{t('FREE_WITHOUT_COST')}</S.Subtitle>
          ) : (
            <S.Subtitle loading={loading}>{formatPrice(currency, price, '')}</S.Subtitle>
          )}
        </S.TextWrapper>
      </S.Wrapper>
    </S.GuideLink>
  )
}

export default withTranslation(GridItem)
