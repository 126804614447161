import useForm from 'components/common/Form/hooks/useForm'
import {useState, useEffect} from 'react'

function useLocalState({initialSelectedItem}) {
  const {Form, setValue} = useForm()
  const [selectedItem, setSelectedItem] = useState()

  useEffect(() => {
    if (initialSelectedItem.title) {
      setValue('locations', initialSelectedItem.title)
    }
  }, [initialSelectedItem.title])

  useEffect(() => {
    if (initialSelectedItem.entityType) {
      setSelectedItem({entityType: initialSelectedItem.entityType})
    }
  }, [initialSelectedItem.entityType])

  return {Form, setValue, selectedItem, setSelectedItem}
}
export default useLocalState
