import styled, {css} from 'styled-components'

const Skeleton = css`
  background-color: ${props => props.theme.colors.gray};

  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff',
      endColorstr='#00ffffff',
      GradientType=1);
  }

  @keyframes loader-animate {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
`

export const GuideLink = styled.a`
  text-decoration: none;
`

export const Wrapper = styled.div`
  display: flex;
  border-radius: 12px;

  ${props => props.theme.mediaQuery.md} {
    flex-direction: column;
    margin-bottom: 10px;
  }
`

export const ImageWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  width: 96px;
  height: 96px;
  flex-shrink: 0;

  ${props => props.theme.mediaQuery.md} {
    width: 224px;
    height: 224px;
  }

  ${props => props.theme.mediaQuery.lg} {
    width: 100%;
    height: 270px;
  }

  ${props => (props.loading ? Skeleton : '')};
`

export const Image = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const TextWrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-top: 11px;
  gap: 6px;
  ${props => props.theme.mediaQuery.md} {
    font-size: 18px;
    line-height: 25px;
    padding-left: 0;
  }
`

export const Title = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${props => (props.loading ? props.theme.colors.gray : props.theme.colors.black)};
  width: ${props => (props.loading ? '22%' : 'auto')};
  height: ${props => (props.loading ? '16px' : 'auto')};
  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
  margin: 0;

  ${props => props.theme.mediaQuery.md} {
    font-size: 18px;
    line-height: 25px;
    padding-left: 0;
  }
`

export const Subtitle = styled.span`
  color: ${props => (props.loading ? props.theme.colors.gray : props.theme.colors.primary1)};
  width: ${props => (props.loading ? '72%' : 'auto')};
  height: ${props => (props.loading ? '16px' : 'auto')};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
`
