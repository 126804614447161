import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: white;
  border-radius: 16px;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  flex: 1 0 0;
  height: auto;
  align-self: stretch;
  white-space: pre-line;
`

export const InfoTitle = styled.h3`
  color: black;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.4px;

  ${props => props.theme.mediaQuery.md} {
    font-size: 20px;
  }
`

export const InfoContent = styled.div`
  color: black;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`
