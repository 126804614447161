import React from 'react'

import * as S from './Styled'

const InfoCard = ({title, content}) => {
  return (
    <S.Wrapper>
      <S.InfoTitle>{title}</S.InfoTitle>
      <S.InfoContent>{content}</S.InfoContent>
    </S.Wrapper>
  )
}

export default InfoCard
