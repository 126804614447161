import React from 'react'

import FAQCard from './FAQCard'
import InfoCard from './InfoCard'

import * as S from './Styled'

const FAQInfo = ({
  historyTitle,
  history,
  culturalSignificanceTitle,
  culturalSignificance,
  faqTitle,
  faq
}) => {
  const anyInfoExists =
    historyTitle ||
    history ||
    culturalSignificanceTitle ||
    culturalSignificance ||
    faqTitle ||
    (faq && Object.keys(faq).length !== 0)

  if (anyInfoExists) {
    return (
      <S.Wrapper>
        <S.InfoCardWrapper>
          {historyTitle && history && <InfoCard title={historyTitle} content={history}></InfoCard>}
          {culturalSignificanceTitle && culturalSignificance && (
            <InfoCard title={culturalSignificanceTitle} content={culturalSignificance}></InfoCard>
          )}
        </S.InfoCardWrapper>
        <S.FAQWrapper>
          <S.FAQTitle>{faqTitle}</S.FAQTitle>
          {faq &&
            Object.keys(faq).map(question => (
              <FAQCard question={question} answer={faq[question]} />
            ))}
        </S.FAQWrapper>
      </S.Wrapper>
    )
  } else {
    return null
  }
}

export default FAQInfo
