import React from 'react'

import {Trans} from 'i18n'
import {useTranslation} from 'react-i18next'

import theme from 'common/helpers/theme'
import CPLink from 'components/CPLink'

import {BECOME_A_HOST} from '../../../routes.json'

import * as S from './Styled'

const RedefineCulture = () => {
  const {t} = useTranslation()

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.Text>{t('REDEFINE_CULTURE_SECTION_HEADING')} </S.Text>
        <S.Title>{t('REDEFINE_CULTURE_SECTION_TITLE')}</S.Title>
        <S.Text>
          <Trans
            i18nKey={'REDEFINE_CULTURE_SECTION_TEXT'}
            components={[
              <S.ColoredText color={theme.colors.primary1} />,
              <S.ColoredText color={theme.colors.primary2} />,
              <S.ColoredText color={theme.colors.primary4} />
            ]}
          />
        </S.Text>
        <CPLink href={`${BECOME_A_HOST.link}`} as={`${BECOME_A_HOST.link}`}>
          <S.Button text={t('LEARN_HOW')} />
        </CPLink>
      </S.ContentWrapper>
    </S.Wrapper>
  )
}

export default RedefineCulture
