import React from 'react'

import styled from 'styled-components'

import AttractionIcon from 'assets/icons/attraction-16x16.svg'
import GuideIcon from 'assets/icons/guide-16x16.svg'
import ExperienceIcon from 'assets/icons/smile-16x16.svg'

import {withTranslation} from '../../index'

function Card({
  name,
  locationId,
  locationType,
  id,
  path,
  experiencesCount,
  attractionsCount,
  guidesCount
}) {
  return (
    <Wrapper href={`/${locationType.toLowerCase()}/${name}-${locationId}`}>
      <GuidesGridCard key={id}>
        <GridCardImage src={path ? path : ''} />
        <GridCardTitle>{name}</GridCardTitle>
        <EntityCountsWrapper>
          {experiencesCount > 0 ? (
            <EntityCount>
              <ExperienceIcon />
              <EntityCountText textColor={'#659F17'}>{experiencesCount}</EntityCountText>
            </EntityCount>
          ) : null}
          {attractionsCount > 0 ? (
            <EntityCount>
              <AttractionIcon />
              <EntityCountText textColor={'#C82853'}>{attractionsCount}</EntityCountText>
            </EntityCount>
          ) : null}
          {guidesCount > 0 ? (
            <EntityCount>
              <GuideIcon />
              <EntityCountText textColor={'#61B7CB'}>{guidesCount}</EntityCountText>
            </EntityCount>
          ) : null}
        </EntityCountsWrapper>
      </GuidesGridCard>
    </Wrapper>
  )
}

export default withTranslation(Card)

const primaryColor = '#253044'
const yellowColor = '#FAA929'

export const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
`

export const GuidesGridCard = styled.article`
  width: 100%;
  scroll-snap-align: start;
  cursor: pointer;

  @media only screen and (min-width: 960px) {
    padding-left: 0;
  }
`

export const GridCardImage = styled.div`
  width: 287px;
  background-color: ${primaryColor};
  padding-bottom: 150%;
  border-radius: 16px;
  margin-bottom: 1rem;
  position: relative;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;

  @media (min-width: 960px) {
    width: auto;
  }
`

export const GridCardTitle = styled.h3`
  max-width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: ${primaryColor};
  margin: 16px 0 4px 0;
`

export const GridCardGuideCount = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${yellowColor};
  margin: 0;
`

export const EntityCountsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

export const EntityCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const EntityCountIcon = styled.img`
  width: 16px;
  height: 16px;
`

export const EntityCountText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.24px;
  color: ${props => props.textColor};
`
