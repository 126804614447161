import React, {useCallback} from 'react'

import SearchComponent from './Search'
import {useTranslation} from 'react-i18next'

import GridAttractionItem from 'components/attractions/components/GridItem'
import GridExperienceItem from 'components/experiences/common/CardItem'
import GridGuideItem from 'components/guides/components/GridItem'
import {observer} from 'lib/model'
import GuideIcon from 'public/static/images/blue_guides_icon.svg'
import ExperienceIcon from 'public/static/images/guides/guides_smile_icon.svg'
import DestinationIcon from 'public/static/images/map-pin-icon-round-gray.svg'
import AttractionIcon from 'public/static/images/red_attraction_icon.svg'

import DestinationCard from '../CPDestinations/components/card'

import FAQInfo from './FAQInfo'
import HomeSection from './HomeSection'
import RedefineCulture from './RedefineCulture'
import useLocalState from './useLocalState'

import * as S from './styled'

const POPULAR_CITIES_LIMIT = 4

const HomePageComponent = props => {
  const {model, ModelProvider, EXPERIENCE_LIMIT, GUIDES_LIMIT, ATTRACTIONS_LIMIT} = useLocalState(
    props
  )
  const {t} = useTranslation()
  return (
    <ModelProvider>
      <S.Layout
        title={model.title}
        infoContent={model.intro}
        isMultiColorGradient={true}
        SearchSection={useCallback(
          () => (
            <SearchComponent
              handleSearchClear={model.handleSearchClear}
              onItemClick={model.onItemClick}
              initialSelectedItem={{title: model.searchPhrase, entityType: model.initialEntityType}}
            />
          ),
          []
        )}
      >
        {model.popularCountries.length > 0 ? (
          <HomeSection
            itemType="destinations"
            items={model.popularCountries}
            ItemComponent={DestinationCard}
            title={t('POPULAR_DESTINATIONS')}
            Icon={DestinationIcon}
            showSeeAllButton={true}
          />
        ) : null}
        {model.attractionsTotal > 0 ? (
          <HomeSection
            itemType="attractions"
            items={model.attractions}
            ItemComponent={GridAttractionItem}
            title={model.attractionListTitle}
            Icon={AttractionIcon}
            subtitle={`${model.attractionsTotal} ${t('ATTRACTIONS_PAGE_TITLE')}`}
            showSeeAllButton={model.attractionsTotal > ATTRACTIONS_LIMIT}
            seeAllQueryParams={model.seeAllQueryParams}
            loading={model.loading}
          />
        ) : null}
        {model.experiencesTotal > 0 ? (
          <HomeSection
            itemType="experiences"
            items={model.experiences}
            ItemComponent={GridExperienceItem}
            title={t('EXPERIENCES')}
            Icon={ExperienceIcon}
            subtitle={`${model.experiencesTotal} ${t('EXPERIENCES')}`}
            showSeeAllButton={model.experiencesTotal > EXPERIENCE_LIMIT}
            seeAllQueryParams={model.seeAllQueryParams}
            loading={model.loading}
          />
        ) : null}
        {model.guidesTotal > 0 ? (
          <HomeSection
            itemType="guides"
            items={model.guides}
            ItemComponent={GridGuideItem}
            title={t('GUIDES_LINK_TITLE')}
            Icon={GuideIcon}
            subtitle={`${model.guidesTotal} ${t('GUIDES_LINK_TITLE')}`}
            showSeeAllButton={model.guidesTotal > GUIDES_LIMIT}
            seeAllQueryParams={model.seeAllQueryParams}
            loading={model.loading}
          />
        ) : null}
        {model.popularCities.length > 0 ? (
          <HomeSection
            itemType="destinations"
            items={
              model.limitPopularCities
                ? model.popularCities.slice(0, POPULAR_CITIES_LIMIT)
                : model.popularCities
            }
            ItemComponent={DestinationCard}
            title={t('POPULAR_DESTINATIONS')}
            Icon={DestinationIcon}
            showSeeAllButton={model.limitPopularCities}
            showSeeAllButtonActionOverride={() => (model.limitPopularCities = false)}
          />
        ) : null}
      </S.Layout>
      <FAQInfo
        historyTitle={model.historyTitle}
        history={model.history}
        culturalSignificanceTitle={model.culturalSignificanceTitle}
        culturalSignificance={model.culturalSignificance}
        faqTitle={model.faqTitle}
        faq={model.faq}
      />
      <RedefineCulture />
    </ModelProvider>
  )
}

export default observer(HomePageComponent)
