import styled from 'styled-components'

import {ButtonPrimary} from 'components/common/buttons'

export const Wrapper = styled.div`
  padding: 96px 14px;
  font-family: Nunito Sans;
  background: #253044;
  position: relative;
  box-sizing: content-box;

  ${props => props.theme.mediaQuery.md} {
    padding: 120px 0 132px 144px;
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(272deg, rgba(37, 48, 68, 0.47) 0%, #253044 100%),
      url('/static/images/redefine-culture.png'), lightgray -280.189px 0px / 71.521% 100% no-repeat;

    ${props => props.theme.mediaQuery.sm} {
      background: linear-gradient(272deg, rgba(37, 48, 68, 0.47) 0%, #253044 100%);
      background-image: url('/static/images/redefine-culture.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    ${props => props.theme.mediaQuery.lg} {
      width: 59%;
    }
    ${props => props.theme.mediaQuery.xxl} {
      width: 39%;
    }
  }
`

export const ContentWrapper = styled.div`
  width: 90%;
  gap: 48px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  ${props => props.theme.mediaQuery.md} {
    width: 70%;
  }
  ${props => props.theme.mediaQuery.xxl} {
    width: 45%;
  }
`

export const Title = styled.div`
  color: white;
  font-size: 56px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -2.24px;

  ${props => props.theme.mediaQuery.md} {
    font-size: 132px;
    letter-spacing: -5.28px;
  }
`

export const Text = styled.div`
  color: white;
  font-size: 18px;
  letter-spacing: -0.72px;
  line-height: 110%;

  ${props => props.theme.mediaQuery.md} {
    font-size: 28px;
    max-width: 560px;
    letter-spacing: -1.12px;
  }
`

export const ColoredText = styled.span`
  color: ${props => props.color};
`

export const Button = styled(ButtonPrimary)`
  padding: 22px 56px;
  font-size: 14px;
  font-weight: 600;
`
