import styled from 'styled-components'

export const Wrapper = styled.div``

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  border: none;
  border-radius: 12px;
  padding: ${props => (props.hasIcon ? '0 16px' : '0 32px')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.4' : '1')};

  > svg {
    max-width: 20px;
    max-height: 20px;
  }

  > :not(:last-child) {
    margin-right: 12px;
  }
`

export const ButtonText = styled.span`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.3;
`
