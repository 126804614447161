import React from 'react'

import {Router} from 'i18n'
import {useTranslation} from 'react-i18next'

import ChevronRight from 'public/static/images/chevron-right-gray.svg'

import * as S from './Styled'

const HomeSection = ({
  itemType,
  items,
  ItemComponent,
  Icon,
  title,
  subtitle,
  showSeeAllButton,
  seeAllQueryParams,
  loading,
  showSeeAllButtonActionOverride
}) => {
  const {t} = useTranslation()

  const seeAllButtonAction = showSeeAllButtonActionOverride
    ? showSeeAllButtonActionOverride
    : () => {
        if (seeAllQueryParams) {
          Router.push(`/${itemType}?${seeAllQueryParams}`)
        } else {
          Router.push(`/${itemType}`)
        }
      }

  return (
    <S.SectionWrapper>
      <S.SectionHeaderWrapper>
        <Icon />
        <S.TitleWrapper>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.TitleWrapper>
      </S.SectionHeaderWrapper>
      <S.ResultGrid loading={loading} item={itemType} ItemComponent={ItemComponent} items={items} />
      {showSeeAllButton ? (
        <S.SeeAllButton
          text={t('SEE_ALL_BUTTON')}
          iconComponent={ChevronRight}
          onClick={() => seeAllButtonAction()}
        />
      ) : null}
    </S.SectionWrapper>
  )
}

export default HomeSection
