/* eslint-disable max-len */

/* eslint-disable complexity */
import {useRouter} from 'next/router'
import {useTranslation} from 'react-i18next'
import {ATTRACTION, EXPERIENCE, GUIDE} from 'routes.json'

import {resolveCurrentLanguage} from 'common/helpers'
import {useLazyGetHomeDataQuery} from 'lib/api/backend/home'
import {useGenerateModel} from 'lib/model/index'

import {ENTITIES} from '../../consts.json'

import {getFormattedLocation} from './helpers'
import {prepareDestinations, prepareExperiences, prepareGuides} from './utils'

const guideLinkBase = GUIDE.VIEW.link
const experienceLinkBase = EXPERIENCE.VIEW.link
const attractionLinkBase = ATTRACTION.VIEW.link
const EXPERIENCE_LIMIT = 6
const EXPERIENCE_OFFSET = 0
const GUIDES_LIMIT = 8
const GUIDES_OFFSET = 0
const ATTRACTIONS_LIMIT = 8
const ATTRACTIONS_OFFSET = 0

function useModel(props) {
  const {t} = useTranslation()
  const router = useRouter()
  const [getHomeData] = useLazyGetHomeDataQuery()

  const {model, ModelProvider} = useGenerateModel({
    loading: false,
    limitPopularCities: true,
    homeData: props.initialHomeData,
    browserTabTitle: props.initialHomeData?.locationDetails?.browserTabTitle,
    metaDescription: props.initialHomeData?.locationDetails?.metaDescription,
    title: props.initialHomeData?.locationDetails?.title
      ? props.initialHomeData?.locationDetails?.title
      : `${t('HOME_HEADER_TITLE_1')} ${t('HOME_HEADER_TITLE_2')}`,
    intro: props.initialHomeData?.locationDetails?.intro
      ? props.initialHomeData?.locationDetails?.intro
      : null,
    attractionListTitle: props.initialHomeData?.locationDetails?.attractionListTitle
      ? props.initialHomeData?.locationDetails?.attractionListTitle
      : t('ATTRACTIONS_PAGE_TITLE'),
    cityListTitle: props.initialHomeData?.locationDetails?.cityListTitle
      ? props.initialHomeData?.locationDetails?.cityListTitle
      : t('KEY_CITIES'),
    historyTitle: props.initialHomeData?.locationDetails?.historyTitle
      ? props.initialHomeData?.locationDetails?.historyTitle
      : null,
    history: props.initialHomeData?.locationDetails?.history
      ? props.initialHomeData?.locationDetails?.history
      : null,
    culturalSignificanceTitle: props.initialHomeData?.locationDetails?.culturalSignificanceTitle
      ? props.initialHomeData?.locationDetails?.culturalSignificanceTitle
      : null,
    culturalSignificance: props.initialHomeData?.locationDetails?.culturalSignificance
      ? props.initialHomeData?.locationDetails?.culturalSignificance
      : null,
    faqTitle: props.initialHomeData?.locationDetails?.faqTitle
      ? props.initialHomeData?.locationDetails?.faqTitle
      : null,
    faq: props.initialHomeData?.locationDetails?.faq
      ? props.initialHomeData?.locationDetails?.faq
      : null,
    popularCountries: props.initialHomeData?.popularCountries
      ? props.initialHomeData?.popularCountries
      : [],
    popularCities: props.initialHomeData?.popularCities ? props.initialHomeData?.popularCities : [],
    experiences: props.initialHomeData?.experiences?.items
      ? prepareExperiences(props.initialHomeData?.experiences?.items, t)
      : [],
    experiencesTotal: props.initialHomeData?.experiences?.total,
    guides: props.initialHomeData?.guides?.items
      ? prepareGuides(props.initialHomeData?.guides?.items, t)
      : [],
    guidesTotal: props.initialHomeData?.guides?.total,
    attractions: props.initialHomeData?.attractions?.items
      ? props.initialHomeData?.attractions?.items.map(item => ({
          ...item,
          src: item.image
        }))
      : [],
    attractionsTotal: props.initialHomeData?.attractions?.total,
    destinations: props.initialHomeData?.destinations
      ? prepareDestinations(props.initialHomeData?.destinations)
      : [],
    seeAllQueryParams:
      router.query?.params && router.query.params[0] && router.query.params[1]
        ? // eslint-disable-next-line max-len
          `searchEntityName=${router.query?.params[1].split('-')[0]}&searchEntityId=${
            router.query?.params[1].split('-')[1]
          }&searchEntityType=${router.query?.params[0]}`
        : null,
    searchPhrase: (router.query.params && router.query.params[1].split('-')[0]) || null,
    initialEntityType: router.query.searchEntityType || null,
    location: props.initialLocation,
    handleTitleChange(item) {
      this.title =
        item &&
        ![ENTITIES.GUIDE, ENTITIES.EXPERIENCE, ENTITIES.ATTRACTION].includes(item.entityType)
          ? `${t('HOME_HEADER_TITLE_1')} - <1>${item.title.split(',')[0]}</1>`
          : `${t('HOME_HEADER_TITLE_1')} ${t('HOME_HEADER_TITLE_2')}`
    },
    handleSearchClear() {
      this.loading = true
      this.handleTitleChange()
      this.setURLParams()
      this.location = null
      this.searchPhrase = null
      this.initialEntityType = null
      this.seeAllQueryParams = null
      this.setHomeData()
    },
    onItemClick(item) {
      this.loading = true
      if (item.entityType === ENTITIES.GUIDE) {
        const itemSlug = item.slug
        const prettyLink = `${guideLinkBase}/${itemSlug}`
        window.open(prettyLink, '_self')
      } else if (item.entityType === ENTITIES.EXPERIENCE) {
        const itemSlug = item.slug
        const prettyLink = `${experienceLinkBase}/${itemSlug}`
        window.open(prettyLink, '_self')
      } else if (item.entityType === ENTITIES.ATTRACTION) {
        const itemSlug = item.slug
        const prettyLink = `${attractionLinkBase}/${itemSlug}`
        window.open(prettyLink, '_self')
      } else {
        const params = [item.entityType, item.title, item.id]
        this.setURLParams(params)
        // eslint-disable-next-line max-len
        this.seeAllQueryParams = `searchEntityName=${item.title}&searchEntityId=${item.id}&searchEntityType=${item.entityType}`
      }
      this.location = getFormattedLocation(item)
      this.handleTitleChange(item)
      this.setHomeData()
    },
    setURLParams(params) {
      if (params?.length > 0) {
        router.replace(`/${params[0]}/${params[1]}-${params[2]}`, undefined, {
          shallow: true
        })
      } else {
        router.replace('/', undefined, {shallow: true})
      }
    },
    setHomeData() {
      this.limitPopularCities = true
      let requestData = {
        languageId: resolveCurrentLanguage(),
        experienceLimit: EXPERIENCE_LIMIT,
        experienceOffset: EXPERIENCE_OFFSET,
        guidesLimit: GUIDES_LIMIT,
        guidesOffset: GUIDES_OFFSET,
        attractionsLimit: ATTRACTIONS_LIMIT,
        attractionsOffset: ATTRACTIONS_OFFSET
      }

      if (this.location) {
        requestData.cityId = this.location.cityId
        requestData.countryId = this.location.countryId
        requestData.regionId = this.location.regionId
      }

      getHomeData(requestData, true).then(newHomeData => {
        this.homeData = newHomeData?.data
        this.browserTabTitle = newHomeData?.data?.locationDetails?.browserTabTitle
          ? newHomeData.data.locationDetails.browserTabTitle
          : t('SEO_HOME')

        if (newHomeData?.data?.locationDetails?.browserTabTitle) {
          if (newHomeData?.data?.locationDetails?.locationType === 'CITY') {
            document.title = newHomeData.data.locationDetails.browserTabTitle
          } else if (newHomeData?.data?.locationDetails?.locationType === 'COUNTRY') {
            document.title = `${newHomeData.data.locationDetails.location.name}: ${newHomeData.data.locationDetails.browserTabTitle}`
          }
        }

        this.metaDescription = newHomeData?.data?.locationDetails?.metaDescription
          ? newHomeData.data.locationDetails.metaDescription
          : t('HOME_PAGE_META_TAG_DESCRIPTION')
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            'content',
            newHomeData?.data?.locationDetails?.metaDescription
              ? newHomeData.data.locationDetails.metaDescription
              : t('HOME_PAGE_META_TAG_DESCRIPTION')
          )
        this.title = newHomeData?.data?.locationDetails?.title
          ? newHomeData?.data?.locationDetails?.title
          : `${t('HOME_HEADER_TITLE_1')} ${t('HOME_HEADER_TITLE_2')}`
        this.intro = newHomeData?.data?.locationDetails?.intro
          ? newHomeData?.data?.locationDetails?.intro
          : null
        this.attractionListTitle = newHomeData?.data?.locationDetails?.attractionListTitle
          ? newHomeData?.data?.locationDetails?.attractionListTitle
          : t('ATTRACTIONS_PAGE_TITLE')
        this.cityListTitle = newHomeData?.data?.locationDetails?.cityListTitle
          ? newHomeData?.data?.locationDetails?.cityListTitle
          : t('KEY_CITIES')
        this.historyTitle = newHomeData?.data?.locationDetails?.historyTitle
          ? newHomeData?.data?.locationDetails?.historyTitle
          : null
        this.history = newHomeData?.data?.locationDetails?.history
          ? newHomeData?.data?.locationDetails?.history
          : null
        this.culturalSignificanceTitle = newHomeData?.data?.locationDetails
          ?.culturalSignificanceTitle
          ? newHomeData?.data?.locationDetails?.culturalSignificanceTitle
          : null
        this.culturalSignificance = newHomeData?.data?.locationDetails?.culturalSignificance
          ? newHomeData?.data?.locationDetails?.culturalSignificance
          : null
        this.faqTitle = newHomeData?.data?.locationDetails?.faqTitle
          ? newHomeData?.data?.locationDetails?.faqTitle
          : null
        this.faq = newHomeData?.data?.locationDetails?.faq
          ? newHomeData?.data?.locationDetails?.faq
          : null
        this.popularCountries = newHomeData?.data?.popularCountries
          ? newHomeData?.data?.popularCountries
          : []
        this.popularCities = newHomeData?.data?.popularCities
          ? newHomeData?.data?.popularCities
          : []
        const preparedExperiences = newHomeData?.data?.experiences?.items
          ? prepareExperiences(newHomeData?.data?.experiences?.items, t)
          : []
        this.experiences = preparedExperiences
        this.experiencesTotal = newHomeData?.data?.experiences?.total

        const preparedGuides = newHomeData?.data?.guides?.items
          ? prepareGuides(newHomeData?.data?.guides?.items, t)
          : []
        this.guides = preparedGuides
        this.guidesTotal = newHomeData?.data?.guides?.total

        this.attractions = newHomeData?.data?.attractions?.items
          ? newHomeData?.data?.attractions?.items.map(item => ({
              ...item,
              src: item.image
            }))
          : []
        this.attractionsTotal = newHomeData?.data?.attractions?.total

        const preparedDestinations = newHomeData?.data?.destinations
          ? prepareDestinations(newHomeData?.data?.destinations)
          : []
        this.destinations = preparedDestinations
        this.loading = false
      })
    }
  })
  return {model, ModelProvider, router}
}

export default useModel
