import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;

  ${props => props.theme.mediaQuery.md} {
    gap: 20px;
  }

  .cp-autocomplete-results-container {
    border-radius: 12px;
    max-width: 368px;
    max-height: 270px;
    height: auto;

    > div {
      height: auto;
      max-height: 270px;
    }
  }
  .cp-autocomplete-clear-btn {
    right: 45px;
    top: 17px;
  }
  .cp-form-icon-text-right-icon {
    pointer-events: none;
  }

  > div:first-child {
    margin-right: 8px;
  }
`
