import styled from 'styled-components'
import ButtonBasic from './basic'

const BUTTON_SECONDARY_BACKGROUND_COLOR = '#F0F2F4'
const BUTTON_SECONDARY_HOVERED_BACKGROUND_COLOR = '#D2D9DF'
const BUTTON_SECONDARY_ENABLED_TEXT_COLOR = '#4A5968'
const BUTTON_SECONDARY_DISABLED_TEXT_COLOR = '#B5BFCA'

const ButtonSecondary = styled(ButtonBasic)`
  color: ${props =>
    props.isDisabled ? BUTTON_SECONDARY_DISABLED_TEXT_COLOR : BUTTON_SECONDARY_ENABLED_TEXT_COLOR};
  background-color: ${BUTTON_SECONDARY_BACKGROUND_COLOR};

  :hover {
    ${props =>
      props.isDisabled ? '' : `background-color: ${BUTTON_SECONDARY_HOVERED_BACKGROUND_COLOR};`}
  }
`

export default ButtonSecondary
