/**
 * CPSpan component
 *
 * @flow
 */
import styled from 'styled-components'

export default styled.span`
  color: ${props => props.color || 'black'};
`
