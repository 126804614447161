import styled from 'styled-components'

export const Wrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;

  ${props => props.theme.mediaQuery.sm} {
    width: 450px;
  }
  .cp-autocomplete-clear-btn {
    right: 16px !important;
  }

  .cp-form-text-input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 48px;
    padding-left: 56px;
    height: 56px;
    background: #ffffff;
    border: 1px solid #d2d9df;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 100px;
    color: ${props => props.theme.colors.secondary10};
    padding-bottom: ${props => (props.showEntityLabel ? '30px' : '')};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;

    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
    }
  }
`

export const EntityLabel = styled.div`
  width: fit-content;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  color: ${props => props.theme.colors.secondary3};
  text-transform: capitalize;
  position: absolute;
  top: 32px;
  left: 56px;
`

export const SuggestedText = styled.span`
  padding: 16px 16px 8px 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 116%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.secondary5};
`
