import React, {useCallback, useEffect, useState} from 'react'

import {Trans} from 'i18n'

import * as S from './styled'

const INITIAL_GRADIENT_HEADER_HEIGHT = 272
const MIN_GRADIENT_HEADER_HEIGHT = 80

const CPFrontPagesLayout = ({
  title,
  SearchSection,
  infoContent,
  headerGradient,
  children,
  className,
  isMultiColorGradient = false
}) => {
  const [height, setHeight] = useState(INITIAL_GRADIENT_HEADER_HEIGHT)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY
    const newHeight = height - scrollTop
    setHeight(newHeight >= MIN_GRADIENT_HEADER_HEIGHT ? newHeight : MIN_GRADIENT_HEADER_HEIGHT)
  }, [])

  return (
    <S.Wrapper className={className}>
      {!isMultiColorGradient && (
        <S.HeaderGradient headerGradient={headerGradient} height={height} />
      )}
      {isMultiColorGradient && (
        <S.TransparentGradient height={height}>
          <S.InnerColoredGradient />
        </S.TransparentGradient>
      )}
      <S.InnerWrapper>
        <S.TitleWrapper className="cp-front-page-layout-title-wrapper">
          <S.Title>
            <Trans i18nKey={title} components={[<S.TitleBold />, <S.TitleBold />]} />
          </S.Title>
        </S.TitleWrapper>
        <S.SearchContentWrapper>
          <S.SearchSectionWrapper className="search-section-wrapper">
            <SearchSection />
          </S.SearchSectionWrapper>
          {infoContent && <S.InfoContentWrapper>{infoContent}</S.InfoContentWrapper>}
          <S.ContentWrapper className="content-wrapper">{children}</S.ContentWrapper>
        </S.SearchContentWrapper>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

export default CPFrontPagesLayout
