import React, {forwardRef} from 'react'

import ReactTooltip from 'react-tooltip'
import {v1} from 'uuid'

import {Spinner} from 'components/common/Loaders'

import * as S from './styled'
import * as T from './types'

const ButtonBasic = forwardRef(
  (
    {
      text = '',
      isLoading = false,
      isDisabled = false,
      onClick = () => {},
      iconComponent = null,
      className,
      type = 'button',
      tooltipText = ''
    }: T.Props,
    ref
  ) => {
    const Icon = isLoading ? Spinner : iconComponent
    let disabled = isLoading || isDisabled
    const uuid = v1()

    return (
      <S.Wrapper className="button-basic-wrapper" data-for={uuid} data-tip={tooltipText}>
        <S.Container
          ref={ref}
          className={className}
          disabled={disabled}
          onClick={onClick}
          hasIcon={!!iconComponent}
          type={type}
        >
          {text ? <S.ButtonText>{text}</S.ButtonText> : null}
          {Icon ? <Icon /> : null}
        </S.Container>
        {tooltipText ? (
          <ReactTooltip
            id={uuid}
            className="menuTooltip"
            place={'left'}
            type={'dark'}
            effect={'float'}
          ></ReactTooltip>
        ) : null}
      </S.Wrapper>
    )
  }
)

export default ButtonBasic
