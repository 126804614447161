/**
 * CPText component
 *
 * @flow
 */

import React from 'react'
import withTranslation from 'common/HOCs/withTranslation'
import CPSpan from './CPSpan'

type Props = {
  tk: String, // key for translation (tried to name it i18n first, but that didn't work)
  color: String
}

const CPText = (props: Props) => {
  const {tk, color} = props
  return <CPSpan color={color}>{tk ? props.t(tk) : props.children}</CPSpan>
}

export default withTranslation(CPText)
