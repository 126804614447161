import styled, {css} from 'styled-components'

const Skeleton = css`
  background-color: ${props => props.theme.colors.gray};

  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff',
      endColorstr='#00ffffff',
      GradientType=1);
  }

  @keyframes loader-animate {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
`

export const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
`

export const ImageWrapper = styled.div`
  border-radius: 12px;
  flex-shrink: 0;
  margin-bottom: 16px;
  width: 100%;
  height: 212px;
  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
`

export const Image = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const OverlayNoteBox = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.32);
  padding: 8px;
  font-style: normal;
  line-height: 1.3;
  color: white;
`

export const NoteLineOne = styled.div`
  font-weight: 400;
  font-size: 12px;
`
export const NoteLineTwo = styled.div`
  font-weight: 700;
  font-size: 14px;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FirstLineText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${props => (props.loading ? props.theme.colors.gray : props.theme.colors.secondary5)};
  width: ${props => (props.loading ? '22%' : 'auto')};
  height: ${props => (props.loading ? '16px' : 'auto')};
  line-height: 130%;
  margin-bottom: 2px;
  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
`

export const TitleLineText = styled.h3`
  font-size: 18px;
  font-weight: 400;
  color: ${props => (props.loading ? props.theme.colors.gray : props.theme.colors.secondary10)};
  width: ${props => (props.loading ? '72%' : 'auto')};
  height: ${props => (props.loading ? '16px' : 'auto')};
  line-height: 130%;
  margin-bottom: 8px;
  margin-top: 0;
  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
`

export const SubtitleLineText = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${props => (props.loading ? props.theme.colors.gray : props.theme.colors.primary1)};
  width: ${props => (props.loading ? '31%' : 'auto')};
  height: ${props => (props.loading ? '16px' : 'auto')};
  line-height: 130%;
  position: relative;
  overflow: hidden;
  ${props => (props.loading ? Skeleton : '')};
`
