import React, {useRef, useState} from 'react'

import withTranslation from 'common/HOCs/withTranslation'
import useOnClickOutside from 'common/hooks/useOnClickOutside'
import {Autocomplete} from 'components/common/Form/components/Fields'
import {ENTITIES} from 'consts.json'
import {useLazyGetHomePageQuery} from 'lib/api/backend/search'

import LocationItem from '../LocationItem'

import useLocalState from './useLocalState'

import * as S from './Styled'

const SEARCH_ICON = '/static/images/search-icon.svg'

function LocationSearch({
  t,
  initialSelectedItem,
  onItemClick = () => {},
  handleSearchClear = () => {}
}) {
  const [getHomePageSearchData] = useLazyGetHomePageQuery()
  const [searchIcon, setSearchIcon] = useState(SEARCH_ICON)

  const {Form, setValue, selectedItem, setSelectedItem} = useLocalState({initialSelectedItem})

  const ref = useRef()
  useOnClickOutside(ref, () => setSearchIcon(SEARCH_ICON))

  const getResults = async value => {
    const {data, isError} = await getHomePageSearchData({phrase: value})

    if (isError) {
      return []
    }

    return data.results
  }

  const onLocationResultClick = item => {
    onItemClick(item)
    setSelectedItem(item)
    setSearchIcon(SEARCH_ICON)
  }

  const handleClear = () => {
    handleSearchClear()
    setSelectedItem()
    setSearchIcon(SEARCH_ICON)
    setValue('locations', null)
  }

  return (
    <S.Wrapper
      ref={ref}
      showEntityLabel={selectedItem?.entityType && selectedItem?.entityType !== ENTITIES.GUIDE}
    >
      <Form>
        <Autocomplete
          name="locations"
          onFocus={() => {
            setSearchIcon('/static/images/search-icon-black.svg')
            if (selectedItem) {
              setSelectedItem(prevState => ({
                ...prevState,
                entityType: ''
              }))
            }
          }}
          placeholder={t('HOME_PAGE_SEARCH_PLACEHOLDER')}
          getAutocompleteResults={value => getResults(value)}
          leftIconSrc={searchIcon}
          searchStartAtChar={1}
          onClear={handleClear}
          handleResultClick={onLocationResultClick}
          ItemComponent={LocationItem}
          onKeyDown={e => {
            if (e.key === 'Backspace') {
              setSelectedItem()
            }
          }}
          isValueSelected={!!initialSelectedItem.title}
        />
      </Form>
      {selectedItem && selectedItem.entityType !== ENTITIES.SPOT && (
        <S.EntityLabel>{selectedItem.entityType}</S.EntityLabel>
      )}
    </S.Wrapper>
  )
}
export default withTranslation(LocationSearch)
