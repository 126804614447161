import React from 'react'

import {useTranslation} from 'react-i18next'

import * as S from './Styled'

const GridItem = props => {
  const {
    slug,
    src,
    firstLineText,
    titleLineText,
    subtitleLineText,
    overlayNoteText,
    loading
  } = props
  const {t} = useTranslation()
  return (
    <S.Wrapper href={`/experience/${slug}`}>
      <S.ImageWrapper loading={loading} className="grid-image-wrapper">
        {loading ? null : <S.Image src={src} />}
        {overlayNoteText && !loading ? (
          <S.OverlayNoteBox>
            <S.NoteLineOne>{t('EXPERIENCE_UPCOMING_NOTE')}</S.NoteLineOne>
            <S.NoteLineTwo>{overlayNoteText}</S.NoteLineTwo>
          </S.OverlayNoteBox>
        ) : null}
      </S.ImageWrapper>
      <S.TextWrapper>
        <S.FirstLineText loading={loading}>{firstLineText}</S.FirstLineText>
        <S.TitleLineText loading={loading}>{titleLineText}</S.TitleLineText>
        <S.SubtitleLineText loading={loading}>{subtitleLineText}</S.SubtitleLineText>
      </S.TextWrapper>
    </S.Wrapper>
  )
}

export default GridItem
