import React from 'react'

import LocationSearch from './components/LocationSearch'

import * as S from './styled'

function Search({initialSelectedItem, onItemClick, handleSearchClear}) {
  return (
    <S.Wrapper>
      <LocationSearch
        initialSelectedItem={initialSelectedItem}
        onItemClick={onItemClick}
        handleSearchClear={handleSearchClear}
      />
    </S.Wrapper>
  )
}

export default Search
