import styled from 'styled-components'

export const ResultComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  min-width: 300px;
  width: min-content;
  cursor: pointer;
  border-radius: 16px;
  height: 56px;
  word-wrap: break-word;

  ${props => props.theme.mediaQuery.xs} {
    min-width: 335px;
  }

  ${props => props.theme.mediaQuery.md} {
    width: 444px;
  }

  :hover {
    background: #f0f2f4;
    border-radius: 8px;
  }
`

export const Icon = styled.img`
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const ResultComponentText = styled.div`
  display: flex;
  line-height: 1.3;
  font-size: 14px;
  color: ${props => props.theme.colors.secondary10};

  ${props => props.theme.mediaQuery.md} {
    max-width: 90%;
  }
`

export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`

export const EntityCountsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

export const EntityCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const EntityCountIcon = styled.img`
  width: 16px;
  height: 16px;
`

export const EntityCountText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.24px;
`
