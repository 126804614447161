import {formatMoney} from 'common/helpers/money'
import {CHECKOUT} from 'consts'

const {DEFAULT_CURRENCY} = CHECKOUT
const dateStringOptions = {year: 'numeric', month: 'short', day: 'numeric'}

export const prepareExperiences = (experiences, t) => {
  return experiences.map(item => ({
    slug: item.slug,
    src: item.image?.path || '',
    firstLineText: item.locationName,
    titleLineText: item.name,
    subtitleLineText:
      item.minPrice === null
        ? ''
        : item.isFree
        ? t('FREE_WITHOUT_COST')
        : t('EXPERIENCE_ITEM_PRICE', {PRICE: formatMoney(DEFAULT_CURRENCY, item.minPrice)}),
    overlayNoteText: item.nextDate
      ? new Date(item.nextDate).toLocaleDateString(undefined, dateStringOptions)
      : ''
  }))
}

export const prepareGuides = (guides, t) => {
  return guides.map(guide => ({
    ...guide,
    subtitle: guide.isFree ? t('FREE_WITHOUT_COST') : guide.priceEuro,
    src: guide?.image?.path
  }))
}

export const prepareDestinations = destinations => {
  return destinations.map(destination => {
    const destinationTitle = destination.country_name
      ? destination.title + ', ' + destination.country_name
      : destination.title

    return {
      ...destination,
      title: destinationTitle
    }
  })
}
