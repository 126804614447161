import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.secondary1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 56px 16px;

  ${props => props.theme.mediaQuery.lg} {
    padding: 80px 144px 120px 144px;
    gap: 80px;
  }
`

export const InfoCardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-direction: column;
  justify-content: stretch;
  max-width: 1152px;

  ${props => props.theme.mediaQuery.lg} {
    flex-direction: row;
  }
`

export const FAQTitle = styled.h2`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  font-size: 22px;
  color: black;
  letter-spacing: -0.44px;
  margin: 0;
`

export const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 1152px;
`
